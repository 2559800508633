export default function ServiceList(root: HTMLElement) {
  const locationId =
    document.querySelector<HTMLDivElement>("[data-location-id]")?.dataset
      .locationId;

  if (locationId) {
    root
      .querySelectorAll<HTMLLinkElement>('[href*="/book-now"]')
      .forEach((link) => {
        const separator = link.href.includes("?") ? "&" : "?";
        link.href = `${link.href}${separator}location=${locationId}`;
      });
  }

  const locationSlug = document.querySelector<HTMLDivElement>(
    "[data-location-slug]",
  )?.dataset.locationSlug;

  if (locationSlug) {
    root
      .querySelectorAll<HTMLLinkElement>("[data-service-slug]")
      .forEach((link) => {
        if (link) {
          const serviceSlug = link.getAttribute("data-service-slug");
          //temp hardcode for now of only iv-drip-therapy
          if (
            serviceSlug &&
            (serviceSlug == "iv-drip-therapy" ||
              serviceSlug == "hydrafacial" ||
              serviceSlug == "nad-iv-drip-therapy" ||
              serviceSlug == "cryotherapy" ||
              serviceSlug == "red-light-therapy" ||
              serviceSlug == "infrared-sauna" ||
              serviceSlug == "compression" ||
              serviceSlug == "circadia-oxygen-facial" ||
              serviceSlug == "cryoskin")
          ) {
            link.href = `/local/${serviceSlug}-${locationSlug}`;
          }
        }
      });
  }
}

import "imask/masked/pattern";

import IMask from "imask/holder";

export default function ContactForm(root: HTMLElement) {
  const maskedInputs = root.querySelectorAll<HTMLElement>("[dk-input-mask]");

  maskedInputs.forEach((element) => {
    const mask = element.getAttribute("dk-input-mask");

    if (mask === "(999) 999-9999") {
      IMask(element, { mask: "(000) 000-0000" });
    }
  });
}

type PostAnonGuidParams = {
  guid: string;
  query: string;
};

type AnonCampaignGuidResponse = { data: { success: boolean } };

export const postAnonUserCampaign = async (
  params: PostAnonGuidParams,
): Promise<boolean> => {
  try {
    const res = await fetch(
      `${process.env.USERS_API}/public/users/campaigns/saw`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          guid: params.guid,
          query: params.query,
          source: "web",
        }),
      },
    ).then(async (res) => (await res.json()) as AnonCampaignGuidResponse);

    return res.data.success;
  } catch (e) {
    return false;
  }
};

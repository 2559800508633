export class GoogleSearchForm {
  constructor(
    formElement: HTMLFormElement,
    callback: (place: google.maps.places.PlaceResult, value: string) => void,
  ) {
    if (formElement) {
      formElement.onsubmit = (event: Event) => event.preventDefault();
    }

    const input = formElement.querySelector("input");

    if (!input) {
      return;
    }

    const searchBox = new google.maps.places.Autocomplete(input, {
      fields: ["formatted_address", "geometry"],
      componentRestrictions: { country: "us" },
    });

    google.maps.event.addListener(searchBox, "place_changed", () => {
      const place = searchBox.getPlace();

      input.value = place.formatted_address ?? input.value;

      callback(place, input.value);
    });
  }
}

import Splide from "@splidejs/splide";

export default function SplideMobile(root: HTMLElement) {
  const list = root.querySelector(".splide__list");
  if (list && list.childNodes.length > 1) {
    new Splide(root, {
      arrows: false,
      mediaQuery: "min",
      breakpoints: {
        768: {
          destroy: true,
        },
      },
    }).mount();
  }
}

export function hideElement(element: HTMLElement) {
  element.style.display = "none";
}

export function asPhone(value: string): string {
  if (value?.match(/coming soon/i)) {
    return value;
  }

  try {
    const digits = value.replace(/\D/g, "");
    const phoneNumber = digits.length === 10 ? digits : digits.substring(1, 11);

    return phoneNumber
      .replace(/\D+/g, "")
      .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  } catch (error) {
    return value;
  }
}

export const focusableSelectors = [
  'a[href]:not([tabindex^="-"])',
  'area[href]:not([tabindex^="-"])',
  'input:not([type="hidden"]):not([type="radio"]):not([disabled]):not([tabindex^="-"])',
  'input[type="radio"]:not([disabled]):not([tabindex^="-"]):checked',
  'select:not([disabled]):not([tabindex^="-"])',
  'textarea:not([disabled]):not([tabindex^="-"])',
  'button:not([disabled]):not([tabindex^="-"])',
  'iframe:not([tabindex^="-"])',
  'audio[controls]:not([tabindex^="-"])',
  'video[controls]:not([tabindex^="-"])',
  '[contenteditable]:not([tabindex^="-"])',
  '[tabindex]:not([tabindex^="-"])',
];

export const convertTag = (el: HTMLElement, tag: string) => {
  const newElement = document.createElement(tag);
  newElement.innerHTML = el.innerHTML;
  Array.from(el.attributes).forEach((attr) => {
    newElement.setAttribute(attr.name, attr.value);
  });
  el.parentNode?.replaceChild(newElement, el);
  return newElement;
};

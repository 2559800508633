import Cookies from "js-cookie";
import * as uuid from "uuid";

import { postAnonUserCampaign } from "../../services/users.ts";
import {
  ANON_GUID_COOKIE_KEY,
  CAMPAIGN_QUERY_COOKIE_KEY,
} from "../constants.ts";

const PREV_SEARCH_PARAMS_KEY = "prev-search-params";

const queryStringToRecord = (query: string): Record<string, string> => {
  const searchParams = new URLSearchParams(query);

  const params: Record<string, string> = {};

  for (const [key, value] of searchParams.entries()) {
    params[key] = value;
  }

  return params;
};

/**
 * Checks if 2 records are identical in their content, order not taken into account.
 * @param prev
 * @param curr
 */
const recordsMatch = (
  prev: Record<string, string>,
  curr: Record<string, string>,
): boolean => {
  const prevEntries = Object.entries(prev);
  const currEntries = Object.entries(curr);

  if (prevEntries.length !== currEntries.length) {
    return false;
  }

  for (const [key, value] of currEntries) {
    const pv = prev[key];
    if (pv !== value) {
      return false;
    }
  }

  return true;
};

const getPrevSearchParams = (): Record<string, string> | null => {
  const prev = localStorage.getItem(PREV_SEARCH_PARAMS_KEY);
  if (!prev) {
    return null;
  }
  try {
    return JSON.parse(prev) as Record<string, string>;
  } catch (e) {
    return null;
  }
};

const setPrevSearchParams = (params: Record<string, string>) => {
  localStorage.setItem(PREV_SEARCH_PARAMS_KEY, JSON.stringify(params));
};

export const handleConsumerCampaignTracking = async () => {
  const query = window.location.search;
  if (!query.includes("utm_") && !query.includes("promotion")) {
    return;
  }

  let anonId = Cookies.get(ANON_GUID_COOKIE_KEY);
  if (!anonId) {
    const tmp = uuid.v4();
    Cookies.set(ANON_GUID_COOKIE_KEY, tmp);
    anonId = tmp;
  }

  const searchRecord = queryStringToRecord(query);
  const searchParams = new URLSearchParams(query);
  const prevSearchParams = getPrevSearchParams();

  const params: Record<string, string> = {};

  for (const [key, value] of searchParams.entries()) {
    params[key] = value;
  }

  Cookies.set(CAMPAIGN_QUERY_COOKIE_KEY, JSON.stringify(params), {
    expires: 7,
  });

  if (
    !prevSearchParams ||
    (prevSearchParams && !recordsMatch(prevSearchParams, searchRecord))
  ) {
    const postAnonUserCampaignResult = await postAnonUserCampaign({
      guid: anonId,
      query: query,
    });

    if (postAnonUserCampaignResult) {
      setPrevSearchParams(searchRecord);
    }
  }
};

export function elementFromTemplate(
  template: HTMLElement,
  tokens: Record<string, string>,
) {
  const element = template.cloneNode(true) as HTMLElement;
  element.removeAttribute("dk-template");

  Object.keys(tokens).forEach((key) => {
    const target = element.querySelector<HTMLElement>(`[dk-value=${key}]`);

    if (!target) {
      return;
    }

    target.innerText = tokens[key];
  });

  return element;
}

const TRANSFORM_OFFSET = 43; // px

export default function ElementsInteractivity(root: HTMLElement) {
  // wait for Lottie to finish playing before attaching listeners
  setTimeout(() => {
    const icons = root.querySelectorAll<HTMLElement>('[id*="-icon"]');

    icons.forEach((icon) => {
      // attach click listener to each icon, to open corresponding modal
      const iconName = icon.getAttribute("id")?.split("-")[0];
      icon.style.cursor = "pointer";

      icon.addEventListener("click", () => {
        const modalTrigger = document.querySelector<HTMLLinkElement>(
          `[href="#modal-${iconName}"]`,
        );

        if (!modalTrigger) {
          return;
        }

        modalTrigger.click();
      });

      // enlarge each icon on hover
      const style = window.getComputedStyle(icon);
      const matrix = style.transform;
      const matrixType = matrix.includes("3d") ? "3d" : "2d";
      const matrixValues = matrix.match(/matrix.*\((.+)\)/)?.[1].split(", ");

      if (!matrixValues || matrixType !== "2d") {
        return;
      }

      const x = matrixValues[4];
      const y = matrixValues[5];
      icon.style.transformOrigin = `${TRANSFORM_OFFSET}px ${TRANSFORM_OFFSET}px`;
      const customStyles = document.createElement("style");
      customStyles.innerHTML = `#${icon.id}:hover {transform: matrix(1.05, 0, 0, 1.05, ${x}, ${y})}`;
      document.head.appendChild(customStyles);
    });

    root.querySelectorAll<HTMLElement>('[id*="-text"]').forEach((label) => {
      // attach click listener to each label, to open corresponding modal
      const labelName = label.getAttribute("id")?.split("-")[0];
      label.style.cursor = "pointer";

      label.addEventListener("click", () => {
        const modalTrigger = document.querySelectorAll<HTMLLinkElement>(
          `[href="#modal-${labelName}"]`,
        )[0];
        if (!modalTrigger) {
          return;
        }
        modalTrigger.click();
      });
    });
  }, 4000);
}

import { Focus } from "../../data";
import FlexibilityRecovery from "./Athletic-FlexibilityRecovery.svg";
import NeurodevelopmentalDisorder from "./Focus-NeurodevelopmentalDisorder.svg";
import OverallCognitiveBoost from "./Focus-OverallCognitiveBoost.svg";
import AutoImmune from "./Immunity-AutoImmune.svg";
import ColdFlu from "./Immunity-ColdFlu.svg";
import EventTravel from "./Immunity-EventTravel.svg";
import PainHeadaches from "./Pain-Headaches.svg";
import PainSurgicalRecovery from "./Pain-SurgicalRecovery.svg";
import SleepOverallRestlessness from "./Sleep-OverallRestlessness.svg";
import SVGSAging from "./SVGsAging.svg";
import SVGsBackAcne from "./SVGsBackAcne.svg";
import SVGsCellulite from "./SVGsCellulite.svg";
import SVGSChronicPain from "./SVGsChronicPain.svg";
import SVGsEnergyVitality from "./SVGsEnergyVitality.svg";
import SVGsFaceAcne from "./SVGsFaceAcne.svg";
import SVGsFaceFirm from "./SVGsFaceFirm.svg";
import SVGsFaceHydrate from "./SVGsFaceHydrate.svg";
import SVGsFaceRosacea from "./SVGsFaceRosacea.svg";
import SVGsFat from "./SVGsFat.svg";
import SVGsHands from "./SVGsHands.svg";
import SVGsImmunityAllergies from "./SVGsImmunityAllergies.svg";
import SVGsMetabolism243 from "./SVGsMetabolism243.svg";
import SVGsNeck from "./SVGsNeck.svg";
import SVGsPerformanceEnergy from "./SVGsPerformanceEnergy.svg";
import SVGsPerformanceRecovery from "./SVGsPerformanceRecovery.svg";
import SVGsSkin from "./SVGsSkin.svg";
import SVGSStress from "./SVGsStress.svg";

export default {
  [Focus.PainChronic]: SVGSChronicPain,
  [Focus.PainHeadaches]: PainHeadaches,
  [Focus.PainRecoveryInjury]: FlexibilityRecovery,
  [Focus.PainRecoverySurgery]: PainSurgicalRecovery,
  [Focus.PerformanceEnergy]: SVGsPerformanceEnergy,
  [Focus.PerformanceRecovery]: SVGsPerformanceRecovery,
  [Focus.ImmuneAutoimmune]: AutoImmune,
  [Focus.ImmuneColdFlu]: ColdFlu,
  [Focus.ImmuneAllergies]: SVGsImmunityAllergies,
  [Focus.ImmuneTravel]: EventTravel,
  [Focus.EnergyVitality]: SVGsEnergyVitality,
  [Focus.EnergySleep]: SleepOverallRestlessness,
  [Focus.FocusCognitive]: OverallCognitiveBoost,
  [Focus.FocusStress]: SVGSStress,
  [Focus.FocusNeurological]: NeurodevelopmentalDisorder,
  [Focus.Aging]: SVGSAging,
  [Focus.WeightFat]: SVGsFat,
  [Focus.WeightMetabolism]: SVGsMetabolism243,
  [Focus.WeightCellulite]: SVGsCellulite,
  [Focus.WeightSkin]: SVGsSkin,
  [Focus.SkinFacialHydrate]: SVGsFaceHydrate,
  [Focus.SkinFacialAcne]: SVGsFaceAcne,
  [Focus.SkinFacialRosacea]: SVGsFaceRosacea,
  [Focus.SkinFacialFirm]: SVGsFaceFirm,
  [Focus.SkinBackAcne]: SVGsBackAcne,
  [Focus.SkinNeck]: SVGsNeck,
  [Focus.SkinHands]: SVGsHands,
} as const;

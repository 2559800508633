import Cookies from "js-cookie";

import DKDialog from "./dk_dialog";

const CONSENT_ACCEPTED = "true";

class Dialog {
  element: HTMLElement;
  dkDialog: DKDialog;
  cookie: boolean;
  modal: boolean;
  modalItself: null | HTMLElement;
  video: boolean;
  videoEl: null | HTMLElement;
  videoSrc: null | string;
  _id: string;

  constructor(element: HTMLElement) {
    this.element = element;
    this.dkDialog = new DKDialog({
      element,
      focusTrapQuery: "[dk-dialog]",
    });
    this.cookie = this.element.hasAttribute("dk-dialog-cookies");
    this.modal = this.element.hasAttribute("dk-dialog-modal");
    this.modalItself = this.element.querySelector("[dk-modal-itself]");
    this.video = this.element.hasAttribute("dk-dialog-video");
    this.videoEl = this.element.querySelector("[dk-dialog-video-src]");

    this.videoSrc = this.videoEl
      ? this.videoEl.getAttribute("src") + "?&autoplay=1&mute=0"
      : null;

    this._id = this.element.id;

    this.dkDialog.on("create", this.modalConstruction);
    this.dkDialog.on("create", this.checkCookie);
    this.dkDialog.on("create", this.ensureRole);
    this.dkDialog.on("create", this.handleNormalOpeners);
    this.dkDialog.on("create", this.handleLinkOpeners);
    this.dkDialog.on("create", this.handleClosers);

    this.dkDialog.on("show", this.handleDialogShow);

    this.dkDialog.on("hide", this.handleDialogHide);

    this.dkDialog.create();
  }

  modalConstruction = () => {
    if (this.modal) {
      this.element.setAttribute("aria-modal", "false");
    }
    if (this.video && this.videoSrc) {
      this.videoEl?.setAttribute("dk-dialog-video-src", this.videoSrc);
    }
    this.element.setAttribute("aria-hidden", "true");
  };

  checkCookie = () => {
    if (!this.cookie) {
      return;
    }

    if (Cookies.get("CookieConsent") === CONSENT_ACCEPTED) {
      this.dkDialog.shown = true;
      this.dkDialog.hide();
    } else {
      this.dkDialog.shown = false;
      this.dkDialog.show();
    }
  };

  ensureRole = () => {
    if (this.element.hasAttribute("role")) {
      return;
    }

    this.element.setAttribute("role", "dialog");
  };

  handleNormalOpeners = () => {
    Array.from(
      document.querySelectorAll(`[dk-dialog-show="${this._id}"]`),
    ).forEach((opener) => {
      opener.addEventListener("click", this.dkDialog.show);
    });
  };

  handleLinkOpeners = () => {
    Array.from(document.querySelectorAll(`a[href="#${this._id}"]`)).forEach(
      (linkOpener) => {
        linkOpener.addEventListener("click", this._linkShow);
      },
    );
  };

  handleClosers = () => {
    Array.from(this.element.querySelectorAll("[dk-dialog-hide]"))
      .concat(
        Array.from(document.querySelectorAll(`[dk-dialog-hide="${this._id}"]`)),
      )
      .forEach((closer) => {
        closer.addEventListener("click", this.dkDialog.hide);
        if (this.cookie === true) {
          closer.addEventListener("click", (event) => {
            Cookies.set("CookieConsent", CONSENT_ACCEPTED, {
              expires: 365 * 40,
            });
            this.dkDialog.hide(event);
          });
        }
      });
  };

  handleDialogShow = () => {
    if (this.modal) {
      this.element.setAttribute("aria-modal", "true");
      document.body.setAttribute("style", "overflow: hidden; cursor: pointer;");
    }
    if (this.video && this.videoSrc) {
      this.videoEl?.setAttribute("src", this.videoSrc);
    }
    this.element.classList.add("open");
    this.element.removeAttribute("aria-hidden");
  };

  handleDialogHide = () => {
    if (this.modal) {
      this.element.setAttribute("aria-modal", "false");
      document.body.removeAttribute("style");
    }
    if (this.video && this.videoSrc) {
      this.videoEl?.setAttribute("src", "");
    }
    this.element.classList.remove("open");
    this.element.setAttribute("aria-hidden", "true");
  };

  _linkShow = (event: Event) => {
    event.stopPropagation();
    this.dkDialog.show(event);
    return this;
  };
}

export default function DialogCreator() {
  document.querySelectorAll<HTMLElement>("[dk-dialog]").forEach((element) => {
    new Dialog(element);
  });
}

import { convertTag } from "lib/helpers";

const ConvertTags = () => {
  document.querySelectorAll<HTMLElement>("[dk-convert-tag]").forEach((node) => {
    const desiredTag = node.getAttribute("dk-convert-tag");
    if (desiredTag === "button") {
      node = convertTag(node, "button");
      node.setAttribute("type", "button");
    }
  });
};

export default ConvertTags;

import { Service } from "../../data";
import Compression from "./Compression.svg";
import CryoFacial from "./CryoFacial.svg";
import CryoSkin from "./CryoSkin.svg";
import HBot from "./HBot.svg";
import Hydrafacial from "./Hydrafacial.svg";
import IMShot from "./IMShot.svg";
import IV from "./IV.svg";
import PBM from "./PBM.svg"; // red light
import Sauna from "./Sauna.svg";
import WBC from "./WBC.svg"; // cryo

const IVIcon = {
  color: "#53BDD8",
  svg: IV,
};

const HydrafacialIcon = {
  color: "#C2EED8",
  svg: Hydrafacial,
};

const CryofacialIcon = {
  color: "#C2EED8",
  svg: CryoFacial,
};

const CryoSkinIcon = {
  color: "#C2EED8",
  svg: CryoSkin,
};

const IMShotIcon = {
  color: "#2C8F82",
  svg: IMShot,
};

export default {
  placeholder: {
    color: "#C2EED8",
    svg: Compression,
  },
  [Service.IVRecharge]: IVIcon,
  [Service.IVWonder]: IVIcon,
  [Service.IVImmunity]: IVIcon,
  [Service.IVBeauty]: IVIcon,
  [Service.IVGetSet]: IVIcon,
  [Service.IVTimeRewind]: IVIcon,
  [Service.IVUnstoppable]: IVIcon,
  [Service.IVDefender]: IVIcon,
  [Service.IVAllergy]: IVIcon,
  [Service.IVAgeDefying]: IVIcon,
  [Service.IVAmplifiedBeauty]: IVIcon,
  [Service.IVMagnesium]: IVIcon,
  [Service.IVEnergy]: IVIcon,
  [Service.IVMyers]: IVIcon,
  [Service.IVRelax]: IVIcon,

  hbot: {
    color: "#B7EBF9",
    svg: HBot,
  },
  [Service.RedLight]: {
    color: "#FF6056",
    svg: PBM,
  },
  wbc: {
    color: "#0081A1",
    svg: WBC,
  },
  infrared_sauna: {
    color: "#FF8C60",
    svg: Sauna,
  },
  compression: {
    color: "#C2EED8",
    svg: Compression,
  },
  nad: IMShotIcon,
  cryo_facial: CryofacialIcon,
  oxygen_facial: HydrafacialIcon,
  cryotoning: CryoSkinIcon,
  cryoslimming: CryoSkinIcon,
  lipo_shot: IMShotIcon,
  premium_hydrafacial: HydrafacialIcon,
  oxygen_facial_addon: HydrafacialIcon,
  cryo_local_facial: CryofacialIcon,
  hydrafacial_body: HydrafacialIcon,
  platinum_hydrafacial: HydrafacialIcon,
} as const;

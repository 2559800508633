export const Focus = {
  PainChronic: "pain_chronic",
  PainHeadaches: "pain_headaches",
  PainRecoveryInjury: "pain_recovery_injury",
  PainRecoverySurgery: "pain_recovery_surgery",
  PerformanceEnergy: "performance_energy",
  PerformanceRecovery: "performance_recovery",
  ImmuneAutoimmune: "immune_autoimmune",
  ImmuneColdFlu: "immune_cold_flu",
  ImmuneAllergies: "immune_allergies",
  ImmuneTravel: "immune_travel",
  EnergyVitality: "energy_vitality",
  EnergySleep: "energy_sleep",
  FocusCognitive: "focus_cognitive",
  FocusStress: "focus_stress",
  FocusNeurological: "focus_neurological",
  Aging: "aging",
  WeightFat: "weight_fat",
  WeightMetabolism: "weight_metabolism",
  WeightCellulite: "weight_cellulite",
  WeightSkin: "weight_skin",
  SkinFacialHydrate: "skin_facial_hydrate",
  SkinFacialAcne: "skin_facial_acne",
  SkinFacialRosacea: "skin_facial_rosacea",
  SkinFacialFirm: "skin_facial_firm",
  SkinBackAcne: "skin_back_acne",
  SkinNeck: "skin_neck",
  SkinHands: "skin_hands",
} as const;

export const Service = {
  WBC: "wbc",
  IVMagnesium: "iv_magnesium",
  IVRecharge: "iv_recharge",
  IVEnergy: "iv_energy",
  IVMyers: "iv_myers",
  IVImmunity: "iv_immunity",
  IVWonder: "iv_wonder",
  RedLight: "red_light",
  IVRelax: "iv_relax",
  HBot: "hbot",
  IVGetSet: "iv_get_set",
  IVTimeRewind: "iv_time_rewind",
  IVUnstoppable: "iv_unstoppable",
  IVDefender: "iv_defender",
  IVAllergy: "iv_allergy",
  IVAgeDefying: "iv_age_defying",
  IVBeauty: "iv_beauty",
  IVAmplifiedBeauty: "iv_amplified_beauty",
  CryoSlimming: "cryoslimming",
  CryoToning: "cryotoning",
  CryoFacial: "cryo_facial",
  CryoLocalFacial: "cryo_local_facial",
  OxygenFacial: "oxygen_facial",
  OxygenFacialAddon: "oxygen_facial_addon",
  HydrafacialBody: "hydrafacial_body",
  PremiumHydrafacial: "premium_hydrafacial",
  LipoShot: "lipo_shot",
  NAD: "nad",
  InfraredSauna: "infrared_sauna",
  Compression: "compression",
  PlatinumHydrafacial: "platinum_hydrafacial",
} as const;

export type FocusOption = {
  id: (typeof Focus)[keyof typeof Focus];
  label: string;
  primary: (typeof Service)[keyof typeof Service];
  secondary: (typeof Service)[keyof typeof Service][];
  upgrade: (typeof Service)[keyof typeof Service] | null;
};

type Goal = {
  id: string;
  label: string;
  focusOptions: FocusOption[];
};

export const goals: Goal[] = [
  {
    id: "manage_pain",
    label: "Manage pain",
    focusOptions: [
      {
        id: Focus.PainChronic,
        label: "General chronic pain",
        primary: Service.WBC,
        secondary: [Service.IVWonder, Service.RedLight],
        upgrade: null,
      },
      {
        id: Focus.PainHeadaches,
        label: "Headaches & migraines",
        primary: Service.IVMagnesium,
        secondary: [Service.WBC, Service.RedLight],
        upgrade: Service.IVRelax,
      },
      {
        id: Focus.PainRecoveryInjury,
        label: "Recovery from an injury",
        primary: Service.HBot,
        secondary: [Service.WBC, Service.RedLight],
        upgrade: null,
      },
      {
        id: Focus.PainRecoverySurgery,
        label: "Recovery from surgery",
        primary: Service.HBot,
        secondary: [Service.WBC, Service.RedLight],
        upgrade: null,
      },
    ],
  },
  {
    id: "athletic_performance",
    label: "Improve athletic performance",
    focusOptions: [
      {
        id: Focus.PerformanceEnergy,
        label: "Energy & endurance",
        primary: Service.IVEnergy,
        secondary: [Service.WBC, Service.RedLight],
        upgrade: Service.IVUnstoppable,
      },
      {
        id: Focus.PerformanceRecovery,
        label: "Recovery",
        primary: Service.IVRecharge,
        secondary: [Service.WBC, Service.Compression],
        upgrade: Service.IVGetSet,
      },
    ],
  },
  {
    id: "immune_system",
    label: "Boost immunity",
    focusOptions: [
      {
        id: "immune_autoimmune",
        label: "Autoimmunity",
        primary: Service.IVRecharge,
        secondary: [Service.WBC, Service.HBot],
        upgrade: Service.IVDefender,
      },
      {
        id: "immune_cold_flu",
        label: "Cold and flu-like symptoms",
        primary: Service.IVMyers,
        secondary: [Service.WBC, Service.RedLight],
        upgrade: Service.IVDefender,
      },
      {
        id: "immune_allergies",
        label: "Allergies",
        primary: Service.IVImmunity,
        secondary: [Service.WBC, Service.HBot],
        upgrade: Service.IVAllergy,
      },
      {
        id: "immune_travel",
        label: "Travel",
        primary: Service.IVMyers,
        secondary: [Service.WBC, Service.HBot],
        upgrade: Service.IVDefender,
      },
    ],
  },
  {
    id: "energy",
    label: "Boost energy & vitality",
    focusOptions: [
      {
        id: "energy_vitality",
        label: "General vitality",
        primary: Service.IVEnergy,
        secondary: [Service.WBC, Service.InfraredSauna],
        upgrade: Service.IVUnstoppable,
      },
      {
        id: "energy_sleep",
        label: "Better sleep",
        primary: Service.IVGetSet,
        secondary: [Service.WBC, Service.RedLight],
        upgrade: Service.IVAllergy,
      },
    ],
  },
  {
    id: "focus",
    label: "Improve focus & mental health",
    focusOptions: [
      {
        id: "focus_cognitive",
        label: "Overall cognitive function",
        primary: "iv_time_rewind",
        secondary: [Service.WBC, Service.RedLight],
        upgrade: Service.IVUnstoppable,
      },
      {
        id: "focus_stress",
        label: "Stress, anxiety & mental health",
        primary: Service.IVMagnesium,
        secondary: [Service.WBC, Service.RedLight],
        upgrade: Service.IVRelax,
      },
      {
        id: "focus_neurological",
        label: "Neurological disorders",
        primary: Service.HBot,
        secondary: [Service.WBC, Service.RedLight],
        upgrade: null,
      },
    ],
  },
  {
    id: "weight",
    label: "Manage weight/target stubborn areas",
    focusOptions: [
      {
        id: "weight_fat",
        label: "Losing fat",
        primary: Service.CryoSlimming,
        secondary: [Service.IVWonder, Service.InfraredSauna],
        upgrade: null,
      },
      {
        id: "weight_metabolism",
        label: "Metabolic Health",
        primary: Service.LipoShot,
        secondary: [Service.WBC, Service.InfraredSauna],
        upgrade: Service.IVWonder,
      },
      {
        id: "weight_cellulite",
        label: "Targeting cellulite",
        primary: Service.CryoToning,
        secondary: [Service.IVUnstoppable, Service.InfraredSauna],
        upgrade: null,
      },
      {
        id: "weight_skin",
        label: "Targeting loose skin",
        primary: Service.CryoToning,
        secondary: [Service.RedLight, Service.WBC],
        upgrade: null,
      },
    ],
  },
  {
    id: "skin",
    label: "Improve skin health",
    focusOptions: [
      {
        id: "skin_facial_hydrate",
        label: "Dehydrated or Hyperpigmented skin",
        primary: Service.PremiumHydrafacial,
        secondary: [Service.CryoLocalFacial, Service.IVAgeDefying],
        upgrade: Service.PlatinumHydrafacial,
      },
      {
        id: "skin_facial_acne",
        label: "Acne or Congested skin",
        primary: Service.OxygenFacial,
        secondary: [Service.CryoLocalFacial, Service.IVBeauty],
        upgrade: null,
      },
      {
        id: "skin_facial_rosacea",
        label: "Rosacea, Dull or Ruddy complex",
        primary: Service.OxygenFacial,
        secondary: [Service.CryoLocalFacial, Service.IVBeauty],
        upgrade: null,
      },
      {
        id: "skin_facial_firm",
        label: "Smooth and Firm Skin",
        primary: Service.CryoFacial,
        secondary: [Service.RedLight, Service.IVBeauty],
        upgrade: Service.OxygenFacialAddon,
      },
      {
        id: "skin_back_acne",
        label: "Back Acne",
        primary: Service.HydrafacialBody,
        secondary: [Service.InfraredSauna, Service.RedLight],
        upgrade: null,
      },
      {
        id: "skin_neck",
        label: "Neck and Decollete",
        primary: Service.HydrafacialBody,
        secondary: [Service.RedLight, Service.CryoLocalFacial],
        upgrade: null,
      },
      {
        id: "skin_hands",
        label: "Hands",
        primary: Service.HydrafacialBody,
        secondary: [Service.RedLight, Service.IVAgeDefying],
        upgrade: null,
      },
    ],
  },
  {
    id: "aging",
    label: "Age with intention/Improve healthspan",
    focusOptions: [
      {
        id: "aging",
        label: "Healthspan",
        primary: Service.NAD,
        secondary: [Service.HBot, Service.InfraredSauna],
        upgrade: null,
      },
    ],
  },
];

export type ServiceOption = {
  label: string;
  id: (typeof Service)[keyof typeof Service];
  description: string;
  duration: string;
  ingredients: string[] | null;
};

export const services = [
  {
    label: "Recharge IV Drip*",
    id: Service.IVRecharge,
    description:
      "Recover after you’ve given it your all! May boost muscle and tissue repair and get back to life with the oomph you need to maximize your potential.",
    duration: "60-90 min",
    ingredients: null,
    type: "iv",
  },
  {
    label: "Wonder Juice IV Drip*",
    id: Service.IVWonder,
    description:
      "An energizing boost for those looking to blast through the week.",
    duration: "60-90 min",
    ingredients: null,
    type: "iv",
  },
  {
    label: "Magnesium IV Drip*",
    id: Service.IVMagnesium,
    description:
      "May support muscle and nerve health. Can induce a relaxing effect.",
    duration: "60-90 min",
    ingredients: null,
    type: "iv",
  },
  {
    label: "Energy IV Drip*",
    id: Service.IVEnergy,
    description: "May increase your energy and boost metabolic health.",
    duration: "60-90 min",
    ingredients: null,
    type: "iv",
  },
  {
    label: "New Myers IV Drip*",
    id: Service.IVMyers,
    description:
      "Promotes general wellness and helps fortify your body’s resistance.",
    duration: "60-90 min",
    ingredients: null,
    type: "iv",
  },
  {
    label: "Immunity IV Drip*",
    id: Service.IVImmunity,
    description:
      "Boost your resilience to environmental stressors with a 1-2 punch of antioxidants",
    duration: "60-90 min",
    ingredients: null,
    type: "iv",
  },
  {
    label: "Get Set IV Drip*",
    id: Service.IVGetSet,
    description:
      "Get a boost to your system and the edge you need to perform your best.",
    duration: "60-90 min",
    ingredients: null,
    type: "iv",
  },
  {
    label: "Time Rewind IV Drip*",
    id: Service.IVTimeRewind,
    description:
      "May support cellular health, restores inner balance, and promotes healthy skin, hair and nails.",
    duration: "60-90 min",
    ingredients: null,
    type: "iv",
  },
  {
    label: "Unstoppable IV Drip*",
    id: Service.IVUnstoppable,
    description:
      "Prep your muscles for an all-out adventure with the ultimate energy booster.",
    duration: "60-90 min",
    ingredients: null,
    type: "iv",
  },
  {
    label: "The Defender IV Drip*",
    id: Service.IVDefender,
    description:
      "Helps you stay on your feet by boosting your body’s natural defense system.",
    duration: "60-90 min",
    ingredients: null,
    type: "iv",
  },
  {
    label: "The Allergy IV Drip*",
    id: Service.IVAllergy,
    description:
      "A powerhouse of antioxidants, histamine blockers, and essential nutrients.",
    duration: "60-90 min",
    ingredients: null,
    type: "iv",
  },
  {
    label: "Relax IV Drip*",
    id: Service.IVRelax,
    description: "Let these nutrients relax, relieve and restore you.",
    duration: "60-90 min",
    ingredients: null,
    type: "iv",
  },
  {
    label: "Age Defying IV Drip*",
    id: Service.IVAgeDefying,
    description:
      "The ultimate collagen boost to maintain healthy tissues— and your glow.",
    duration: "60-90 min",
    ingredients: null,
    type: "iv",
  },
  {
    label: "Amplified Beauty IV Drip*",
    id: Service.IVBeauty,
    description:
      "Relax, recover and restore your youthful glow with this nourishing drip.",
    duration: "60-90 min",
    ingredients: null,
    type: "iv",
  },
  {
    label: "Mild Hyperbaric Oxygen Therapy*",
    id: Service.HBot,
    description:
      "Regain your edge. Our MHbOT chambers are designed to enhance your body's natural healing processes. It may help optimize sleep, speed up athletic recovery, repair muscles and boost cognitive clarity.",
    duration: "60-90 min",
    ingredients: null,
    type: Service.HBot,
  },
  {
    label: "Red Light Therapy",
    id: Service.RedLight,
    description:
      "Uses red and infrared wavelengths of light that may help the body boost energy levels, relieve minor pain and swelling, and balance your mood.",
    duration: "10 min",
    ingredients: null,
    type: Service.RedLight,
  },
  {
    label: "Whole Body Cryotherapy",
    id: Service.WBC,
    description:
      "Our Whole Body Cryotherapy may help the body optimize sleep and defy signs of aging while boosting overall energy.",
    duration: "2-3 min",
    ingredients: null,
    type: "cryo",
  },
  {
    label: "Infrared Sauna",
    id: Service.InfraredSauna,
    description:
      "Lay back, relax and unwind.  Benefits may include burning calories, relief from inflammation, relief from minor pain, acceleration of athletic recovery, boost of circulation and improvment of cardiovascular health.",
    duration: "30-45 min",
    ingredients: null,
    type: Service.InfraredSauna,
  },
  {
    label: "Compression Therapy",
    id: Service.Compression,
    description:
      "Compression may help your body improve the flow of blood and lymphatic fluid, boost recovery, circulation and healing. ",
    duration: "30 - 60 min",
    ingredients: null,
    type: Service.Compression,
  },
  {
    label: "CryoSlimming®",
    id: Service.CryoSlimming,
    description:
      "CryoSlimming, which operates at different temperature settings, is applied with a massaging technique. Benefits may include the appearance of smoother skin and reduction of cellulite.",
    duration: "30 - 60 min",
    ingredients: null,
    type: "este",
  },
  {
    label: "CryoToning®",
    id: Service.CryoToning,
    description:
      "Firm and tighten with the power of cold. Benefits may include the appearance of smoother skin, reduction of cellulite, and improved overall texture and appearance of complexion",
    duration: "30 - 60 min",
    ingredients: null,
    type: "este",
  },
  {
    label: "Lipo IM Shot*",
    id: Service.LipoShot,
    description:
      "This trifecta of amino acids may help boost the body’s metabolic power.",
    duration: "10 min",
    ingredients: null,
    type: "este",
  },
  {
    label: "Premium HydraFacial®",
    id: Service.PremiumHydrafacial,
    description:
      "This non-invasive treatment extracts impurities and exfoliates, then infuses restorative ingredients like nourishing antioxidant peptides to leave you looking and feeling radiant.",
    duration: "60 min",
    ingredients: null,
    type: "este",
  },
  {
    label: "Circadia® Oxygen Facial",
    id: Service.OxygenFacial,
    description:
      "This breakthrough facial utilizes a controlled amount of oxygen generated at optimal levels in the skin to provide maximum benefits and replace the ruddy look associated with acne, rosacea, and telangiectasia, resulting in a brighter complexion.",
    duration: "60 min",
    ingredients: null,
    type: "este",
  },
  {
    label: "Circadia® Oxygen Facial Add-On",
    id: Service.OxygenFacialAddon,
    description:
      "Respiratory oxygen is critical for sustaining life and can be of great benefit when applied topically for all skin conditions. This 10 min add-on helps Reduces p. Acne bacteria by providing an oxygenated environment, reduces inflammatory cytokines and reduce inflammation, and generates cellular energy (ATP) that can assist in increase circulation, metabolic activity and collagen and elastin stimulation.  Making this an add-on for all skin types.",
    duration: "10 min",
    ingredients: null,
    type: "este",
  },
  {
    label: "Cryoskin Facial",
    id: Service.CryoFacial,
    description:
      "A service that leverages cold temperatures that may increase blood flow and oxygen, reducing the appearance of wrinkles and pores while improving tightness and firmness of skin.",
    duration: "30-60 min",
    ingredients: null,
    type: "este",
  },
  {
    label: "Local Cryo Facial",
    id: Service.CryoLocalFacial,
    description:
      "This targeted cold treatment may boost collagen production and decrease pore size.",
    duration: "10-15 min",
    ingredients: null,
    type: "este",
  },
  {
    label: "HydraFacial® Body",
    id: Service.HydrafacialBody,
    description:
      "This non-invasive treatment extracts impurities and exfoliates, then infuses restorative ingredients like nourishing antioxidant peptides to leave you looking and feeling radiant.",
    duration: "60 min",
    ingredients: null,
    type: "este",
  },
  {
    label: "Platinum HydraFacial®",
    id: Service.PlatinumHydrafacial,
    description:
      "This non-invasive treatment extracts impurities and exfoliates, then infuses restorative ingredients like nourishing antioxidant peptides to leave you looking and feeling radiant.",
    duration: "60 min",
    ingredients: null,
    type: "este",
  },
  {
    label: "NAD+ Therapy*",
    id: Service.NAD,
    description:
      "May help your body jumpstart cellular repair mode, rejuvenate energy and clean house of inner damage. IV Therapy includes 1 liter of hydration; IM Shots include a Cryotherapy prep.",
    duration: "1-4 hours",
    ingredients: null,
    type: "shot",
  },
];

export const queryForTarget = <T extends Element>(
  root: Element,
  target: string,
) => {
  return root.querySelector<T>(`[dk-target="${target}"]`);
};

export const queryForTargets = <T extends Element>(
  root: Element,
  target: string,
) => {
  return root.querySelectorAll<T>(`[dk-target="${target}"]`);
};

export const queryForTemplate = <T extends Element>(
  root: Element,
  name: string,
) => {
  return root.querySelector<T>(`[dk-template="${name}"]`);
};

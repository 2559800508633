import { queryForTargets } from "core/content";

function parseHour(hour: number) {
  const ampm = hour >= 12 ? "PM" : "AM";
  const hour12 = hour % 12 || 12;

  return `${hour12}${ampm}`;
}

function parseHours(hours: string) {
  const splitHours = hours.split("-");

  if (splitHours.length === 1) {
    return splitHours[0];
  }
  const open = parseHour(parseInt(splitHours[0]));
  const close = parseHour(parseInt(splitHours[1]));

  return `${open}-${close}`;
}

export default function Hours(root: HTMLElement) {
  const hoursElements = queryForTargets<HTMLElement>(root, "hours");

  const days = Array.from(hoursElements).reduce<
    {
      startDay: string;
      endDay?: string;
      hours: string;
    }[]
  >((acc, element) => {
    const previousDay = acc[acc.length - 1];
    const day = element.getAttribute("dk-value")!;
    const hours = parseHours(element.innerText);

    if (previousDay?.hours === hours) {
      previousDay.endDay = day;
    } else {
      acc.push({ startDay: day, hours });
    }

    return acc;
  }, []);

  root.replaceChildren(
    ...days.map((day) => {
      const el = document.createElement("div");

      el.innerText = `${day.startDay}${day.endDay ? `-${day.endDay}` : ""}: ${
        day.hours
      }`;

      return el;
    }),
  );

  root.style.display = "block";
}

type StoresResponse = {
  data: {
    store_code: string;
    description: string;
    email: string;
    phone: string;
    is_pow: boolean;
    website_url: string;
    location: {
      address: string;
      address2?: string;
      city: string;
      state: string;
      zip: string;
    };
    coordinates: {
      longitude: number;
      latitude: number;
    };
    is_live: boolean;
    is_visible?: boolean;
  }[];
};

export type Studio = Awaited<ReturnType<typeof fetchStores>>[number];

const parameterize = (store: StoresResponse["data"][number]) =>
  `${store.location.state || "x"}-${store.location.city || "x"}`;

export async function fetchStores() {
  const response = await fetch(`${process.env.BOOKING_API}/stores`);
  const { data }: StoresResponse = (await response.json()) as StoresResponse;

  return data
    .filter((store) => store.is_visible)
    .sort((a, b) => parameterize(a).localeCompare(parameterize(b)))
    .map((store) => ({
      storeCode: store.store_code,
      description: store.description,
      address: store.location.address,
      address2: store.location.address2,
      city: store.location.city,
      state: store.location.state,
      zip: store.location.zip,
      email: store.email,
      phone: store.phone,
      url: store.website_url,
      distance: 0,
      latitude: store.coordinates?.latitude,
      longitude: store.coordinates?.longitude,
      studioid: 0,
      isLive: store.is_live,
      isPow: store.is_pow,
      isVisible: store.is_visible,
    }));
}

import Splide from "@splidejs/splide";

export default function SplideAwards(root: HTMLElement) {
  const list = root.querySelector(".splide__list");

  if (list && list.childNodes.length > 1) {
    new Splide(root, {
      autoplay: true,
      arrows: false,
      mediaQuery: "min",
      perPage: 2,
      perMove: 2,
      interval: 4000,
      breakpoints: {
        768: {
          perPage: 3,
          perMove: 3,
          interval: 8000,
        },
      },
    }).mount();
  }
}

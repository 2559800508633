import { convertTag } from "lib/helpers";

const ESCAPE_KEY = "Escape";

export default function NavDropdowns() {
  // find all dropdown toggles
  // use them as the logical basis for managing dropdowns
  document.querySelectorAll("[dk-nav-dropdown-toggle]").forEach((toggle) => {
    // basic state management
    let opened = false;
    let mobileOnly = false;

    // handle scenarios in which dropdowns
    // should only function on mobile:
    if (!(toggle.getAttribute("dk-mobile-only") === null)) {
      mobileOnly = true;
      let mobileBreakpoint = toggle.getAttribute("dk-mobile-only");
      if (mobileBreakpoint === "#") {
        mobileBreakpoint = "991";
      }
      const mobileBreakpointForMediaQuery = parseInt(mobileBreakpoint!) + 1;
      const mediaQuery = window.matchMedia(
        `(min-width: ${mobileBreakpointForMediaQuery}px)`,
      );

      // check if window is currently desktop-size
      const isDesktop = () => {
        return mediaQuery.matches;
      };

      if (isDesktop()) {
        toggle.removeAttribute("aria-expanded");
      } else {
        toggle.setAttribute("aria-expanded", "false");
      }

      // when window resized, re-check if desktop or mobile
      let timeoutFunctionId: NodeJS.Timeout;
      window.addEventListener("resize", () => {
        clearTimeout(timeoutFunctionId);
        timeoutFunctionId = setTimeout(() => {
          if (isDesktop() && opened) {
            closeDropdown();
            toggle.removeAttribute("aria-expanded");
          }
          if (isDesktop()) {
            toggle.removeAttribute("aria-expanded");
          }
          if (!isDesktop && opened) {
            toggle.setAttribute("aria-expanded", "true");
          }
        }, 350);
      });
    }

    // for all other dropdowns:
    const dropdownID = toggle.getAttribute("dk-nav-dropdown-toggle");
    // if a target id is specified,
    if (!(dropdownID === "#") && !(dropdownID === null)) {
      // try to find a target element with that id
      const dropdown = document.getElementById(dropdownID);
      if (!dropdown) {
        return;
      }
      // if found, add appropriate roles
      dropdown.setAttribute("role", "menu");
      const menuLinks = dropdown.getElementsByTagName("a");
      Array.from(menuLinks).forEach((link) => {
        link.setAttribute("role", "menuitem");
      });
    }

    // format the dropdown toggle itself
    toggle = convertTag(toggle as HTMLElement, "button");
    toggle.setAttribute("type", "button");

    // if allowed to function on desktop, label as unexpanded on page load
    if (!mobileOnly) {
      toggle.setAttribute("aria-expanded", "false");
    }

    const dropdownParent = toggle.parentElement!;

    function openDropdown() {
      // label as expanded
      toggle.setAttribute("aria-expanded", "true");
      // add .open class to parent to visually "open" dropdown
      // note: this assumes your CSS will handle hide/show properly
      toggle.parentElement?.classList.add("open");
      // update opened "state"
      opened = true;
      // listen for click events outside dropdown
      document.addEventListener("click", closeDropdownOnOutsideClick);
      // listen for escape key press
      document.addEventListener("keyup", closeDropdownOnEscPress);
    }

    function closeDropdown() {
      // label as collapsed
      toggle.setAttribute("aria-expanded", "false");
      // remove .open class from parent to visually "close" dropdown
      toggle.parentElement?.classList.remove("open");
      // update opened "state"
      opened = false;
      // remove listeners since no longer needed
      document.removeEventListener("click", closeDropdownOnOutsideClick);
      document.removeEventListener("keyup", closeDropdownOnEscPress);
    }

    const closeDropdownOnOutsideClick = (event: Event) => {
      if (!dropdownParent.contains(event.target as Node)) {
        closeDropdown();
      }
    };

    function closeDropdownOnEscPress(event: KeyboardEvent) {
      if (event.key === ESCAPE_KEY) {
        event.preventDefault();
        closeDropdown();
        (toggle as HTMLElement).focus();
      }
    }

    function handleToggleClick(event: Event) {
      event.preventDefault();
      if (!opened) {
        openDropdown();
      } else {
        closeDropdown();
      }
    }

    // attach click listener to toggle
    toggle.addEventListener("click", (event) => {
      handleToggleClick(event);
    });

    // find any close buttons
    Array.from(document.querySelectorAll("[dk-nav-dropdown-close]")).forEach(
      (closer) => {
        const dropdownParent = closer.closest("[dk-nav-dropdown-parent]");
        if (!dropdownParent) {
          return;
        }
        closer.addEventListener("click", function () {
          if (dropdownParent.classList.contains("open")) {
            closeDropdown();
          }
        });
      },
    );
  });

  // hoist .w--current up to parent dropdown, if present
  Array.from(
    document.querySelectorAll(".w--current:not(.never-current)"),
  ).forEach((currentItem) => {
    const dropdownParent = currentItem.closest("[dk-nav-dropdown-parent]");
    if (!dropdownParent) {
      return;
    }
    // add .w--current to the element just inside the dropdown parent, for styling
    dropdownParent.firstElementChild!.classList.add("w--current");
  });

  // special scenario: when a location detail page is the current page, add
  // .w--current to the Locations primary nav item, which doesn't have a dropdown
  if (window.location.pathname.includes("locations")) {
    const locationsNavLink = document.querySelectorAll(
      '.primary-nav__item > a[href*="locations"]',
    )[0];
    if (!locationsNavLink) {
      return;
    }
    setTimeout(() => {
      locationsNavLink.classList.add("w--current");
    }, 100);
  }

  // special scenario 2: when a blog post is the current page, add .w--current to
  // the Hyper Wellness primary nav item's first child, which doesn't actually contain blog posts
  if (window.location.pathname.includes("blog")) {
    // find the blog link in the nav by its href, because its name or class could change
    const blogNavLink = document.querySelectorAll(
      '.primary-nav__item a[href*="blog"]',
    )[0];
    if (!blogNavLink) {
      return;
    }
    // find its parent nav item
    const blogNavLinkParentItem = blogNavLink.closest(".primary-nav__item");
    if (!blogNavLinkParentItem) {
      return;
    }
    // find that nav item's first child, which needs the .w--current class for CSS
    const blogNavLinkParentButton = blogNavLinkParentItem.firstElementChild;
    if (!blogNavLinkParentButton) {
      return;
    }
    // use 0ms timeout trick to move this task to the end of the event queue
    setTimeout(() => {
      blogNavLinkParentButton.classList.add("w--current");
    }, 0);
  }
}

import Splide from "@splidejs/splide";
import { hideElement } from "lib/helpers";

function buildReferrizerDiv(id: string) {
  const referrizerDiv = document.createElement("div");
  referrizerDiv.id = "referrizer-banner-widget";
  referrizerDiv.setAttribute("data-aid", id);
  referrizerDiv.setAttribute("style", "width: 100%; min-height: 335px;");

  return referrizerDiv;
}

export default function SplideLocationPromos(root: HTMLElement) {
  const list = root.querySelector<HTMLElement>(".splide__list");
  // console.log("list", list);

  const referrizerId = document
    .querySelector("[dk-referrizer-id]")
    ?.getAttribute("dk-referrizer-id");

  if (!referrizerId) {
    // eslint-disable-next-line no-console
    console.warn("Missing element with dk-referrizer-id attribute");
  }

  if (referrizerId?.length) {
    const referrizerParent =
      document.querySelector<HTMLElement>("[dk-referrizer-id]");
    const newReferrizerDiv = buildReferrizerDiv(referrizerId);
    referrizerParent?.appendChild(newReferrizerDiv);

    if (list) {
      hideElement(list);
    }
  } else {
    if (list?.childNodes.length) {
      new Splide(root, {
        autoplay: true,
        arrows: false,
        type: "loop",
      }).mount();
    }
  }
}

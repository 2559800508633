import { queryForTarget } from "core/content";
import { elementFromTemplate } from "core/template";
import { convertDistance, getDistance } from "geolib";
import { asPhone } from "lib/helpers";
import type { Studio } from "services/booking";

export function renderStudioTemplate(
  template: HTMLElement,
  studio: Studio,
  showDistance?: boolean,
  serviceId?: string,
) {
  const name = studio.isLive
    ? studio.description
    : `${studio.description} (Coming Soon)`;

  const element = elementFromTemplate(template, {
    name,
    address: studio.address,
    address2: studio.address2 ?? "",
    locality: studio.city,
    state: studio.state,
    postal_code: studio.zip,
    "phone-number": studio.phone && asPhone(studio.phone),
    distance: `Approx. ${Math.ceil(studio.distance)} miles away`,
  });

  queryForTarget<HTMLLinkElement>(element, "name")?.setAttribute(
    "href",
    studio.url,
  );

  queryForTarget<HTMLLinkElement>(element, "phone-number")?.setAttribute(
    "href",
    `tel:${studio.phone}`,
  );

  if (serviceId) {
    queryForTarget<HTMLLinkElement>(element, "book")?.setAttribute(
      "href",
      `/book-now?location=${studio.storeCode}&service=${serviceId}`,
    );
  } else {
    queryForTarget<HTMLLinkElement>(element, "book")?.setAttribute(
      "href",
      `/book-now?location=${studio.storeCode}`,
    );
  }

  if (!studio.isLive) {
    queryForTarget<HTMLLinkElement>(element, "book")?.remove();
  }

  if (showDistance === false) {
    element.querySelector(".result-distance-wrap")?.remove();
  }

  return element;
}

type Coordinates = {
  latitude: number;
  longitude: number;
  locationName?: string;
};

function getLocationDistance(location: Studio, coordinates: Coordinates) {
  const { latitude, longitude } = location;

  if (!latitude || !longitude) {
    return 0;
  }

  const proximity = getDistance(
    { latitude: coordinates.latitude, longitude: coordinates.longitude },
    { latitude, longitude },
  );

  return convertDistance(proximity, "mi");
}

export class LocationList {
  private element: HTMLElement;
  private options: {
    renderStudio: (studio: Studio, showDistance?: boolean) => HTMLElement;
  };

  constructor(element: HTMLElement, options: LocationList["options"]) {
    this.element = element;
    this.options = options;
  }

  sortLocations(locations: Studio[], coordinates: Coordinates) {
    return locations
      .map((location) => ({
        ...location,
        distance: getLocationDistance(location, coordinates),
      }))
      .sort((a, b) => a.distance - b.distance);
  }

  renderStudios(locations: Studio[], coordinates?: Coordinates) {
    const locationTemplates = coordinates
      ? this.sortLocations(locations, coordinates).map((location) =>
          this.options.renderStudio(location),
        )
      : locations.map((location) => this.options.renderStudio(location, false));

    this.element.replaceChildren(...locationTemplates);
  }
}

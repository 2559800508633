import type { Studio } from "services/booking";
import type { StoreApi } from "zustand/vanilla";

export type StudioStore = {
  studios: Studio[];
  setStudios: (studios: Studio[]) => void;
};

export type Stores = {
  studioStore: StoreApi<StudioStore>;
};

type Component<Stores> = (element: HTMLElement, stores: Stores) => void;

export function bootstrapComponents<Stores>(
  config: Record<string, Component<Stores>>,
  stores: Stores,
) {
  Object.keys(config).forEach((name) => {
    const component = config[name];
    document
      .querySelectorAll<HTMLElement>(`[dk-component="${name}"]`)
      .forEach((element) => {
        component(element, stores);
      });
  });
}

import type { Stores } from "core/bootstrap";
import { queryForTarget, queryForTemplate } from "core/content";
import { convertTag } from "lib/helpers";
import { GoogleMap } from "modules/google-maps";
import { GoogleSearchForm } from "modules/google-search";
import { LocationList, renderStudioTemplate } from "modules/location-list";
import { createStore } from "zustand/vanilla";

type Coordinates = {
  latitude: number;
  longitude: number;
  locationName?: string;
};

type CoordinateStore = {
  coordinates?: Coordinates;
  setCoordinates: (coordinates: Coordinates) => void;
};

export default function Location(root: Element, { studioStore }: Stores) {
  const coordinateStore = createStore<CoordinateStore>((set) => ({
    setCoordinates: (coordinates) => set({ coordinates }),
  }));

  const mapElement = queryForTarget<HTMLElement>(root, "locations-map");
  const formElement = queryForTarget<HTMLFormElement>(
    root,
    "location-search-form",
  );
  const locationTemplate = queryForTemplate<HTMLElement>(
    root,
    "locations-result",
  );
  const listElement = queryForTarget<HTMLElement>(root, "locations-list");

  if (!mapElement || !formElement || !listElement || !locationTemplate) {
    return;
  }

  const googleMap = new GoogleMap(mapElement, {
    dragToggle: queryForTarget<HTMLInputElement>(root, "search-as-i-move"),
    onDragEnd: (coordinates: Coordinates) =>
      coordinateStore.getState().setCoordinates(coordinates),
    renderTooltip: (studio) => {
      const tooltip = renderStudioTemplate(locationTemplate, studio, false);
      return convertTag(tooltip, "div");
    },
    onMarkerClick: (coordinates) =>
      coordinateStore.getState().setCoordinates(coordinates),
    showMapButton: queryForTarget<HTMLButtonElement>(root, "show-map"),
    mapAndControls: queryForTarget<HTMLElement>(root, "map-and-controls"),
  });

  const list = new LocationList(listElement, {
    renderStudio: (studio, showDistance) =>
      renderStudioTemplate(locationTemplate, studio, showDistance),
  });

  new GoogleSearchForm(formElement, (place, value) => {
    if (!place?.geometry?.viewport || !place.geometry.location) {
      return;
    }

    const latitude = place.geometry.location.lat();
    const longitude = place.geometry.location.lng();

    const swBound = place.geometry.viewport.getSouthWest();
    const neBound = place.geometry.viewport.getNorthEast();
    const bounds = new google.maps.LatLngBounds(swBound, neBound);

    googleMap?.fitBounds(bounds);

    coordinateStore.getState().setCoordinates({
      latitude,
      longitude,
      locationName: value,
    });
  });

  studioStore.subscribe((state) => list.renderStudios(state.studios));

  coordinateStore.subscribe((state) =>
    list.renderStudios(studioStore.getState().studios, state.coordinates),
  );

  studioStore.subscribe((state) => googleMap.renderMarkers(state.studios));

  list.renderStudios(studioStore.getState().studios);
  googleMap.renderMarkers(studioStore.getState().studios);
}

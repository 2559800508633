import type { Stores, StudioStore } from "core/bootstrap";
import {
  queryForTarget,
  queryForTargets,
  queryForTemplate,
} from "core/content";
import { convertTag } from "lib/helpers";
import { GoogleMap } from "modules/google-maps";
import { LocationList, renderStudioTemplate } from "modules/location-list";
import { createStore } from "zustand/vanilla";

export default function CoopRegions(
  root: HTMLElement,
  { studioStore }: Stores,
) {
  const mapElement = queryForTarget<HTMLElement>(root, "coop-locations-map");
  const listElement = queryForTarget<HTMLElement>(root, "locations-list");
  const locationTemplate = queryForTemplate<HTMLElement>(
    root,
    "locations-result",
  );
  const serviceId =
    document.querySelector<HTMLDivElement>("[data-service-id]")?.dataset
      .serviceId;

  if (!listElement || !mapElement || !locationTemplate) {
    return;
  }

  const coopStudioStore = createStore<StudioStore>((set) => ({
    studios: [],
    setStudios: (studios) => set({ studios }),
  }));

  const coopStoresList = queryForTarget<HTMLDivElement>(root, "coop-stores");

  if (coopStoresList) {
    coopStoresList.style.display = "none";
  }

  const storeIds = Array.from(
    queryForTargets<HTMLElement>(root, "store-id"),
  ).map((element) => element.innerText);

  const googleMap = new GoogleMap(mapElement, {
    renderTooltip: (studio) => {
      const tooltip = renderStudioTemplate(
        locationTemplate,
        studio,
      );
      return convertTag(tooltip, "div");
    },
    showMapButton: queryForTarget<HTMLButtonElement>(root, "show-map"),
    mapAndControls: queryForTarget<HTMLElement>(root, "map-and-controls"),
  });

  const list = new LocationList(listElement, {
    renderStudio: (studio) => renderStudioTemplate(locationTemplate, studio, true, serviceId),
  });

  coopStudioStore.subscribe((state) => list.renderStudios(state.studios));

  coopStudioStore.subscribe((state) => googleMap.renderMarkers(state.studios));

  if (studioStore.getState().studios) {
    const studios = studioStore.getState().studios;
    coopStudioStore
      .getState()
      .setStudios(
        studios.filter((studio) => storeIds.includes(studio.storeCode)),
      );
  }

  studioStore.subscribe((state) => {
    coopStudioStore
      .getState()
      .setStudios(
        state.studios.filter((studio) => storeIds.includes(studio.storeCode)),
      );
  });
}

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    __DK__: boolean;
  }
}

export async function startRestoreApp(app: () => Promise<void>) {
  const isStaging = window.location.host.match(/webflow.io/);

  if (isStaging && !window.__DK__) {
    const script = document.createElement("script");
    script.src = "http://localhost:9000/index.js";
    script.id = "dk";

    document.body.appendChild(script);
    window.__DK__ = true;

    script.onload = () => {
      // eslint-disable-next-line no-console
      console.log("%c 🐵 DK Started", "font-weight: bold");
    };

    script.onerror = async () => {
      await app();
    };
  } else {
    await app();
  }
}

import type { Components } from "@splidejs/splide";
import Splide from "@splidejs/splide";

export default function SplideBlogCategories(root: HTMLElement) {
  // https://splidejs.com/guides/extension/#example
  const slideNumber = (Splide: Splide, Components: Components) => {
    const list = Components.Elements?.list;
    let elem: HTMLElement;

    const mount = () => {
      elem = document.createElement("div");
      elem.style.textAlign = "center";
      elem.style.marginTop = "0.5em";
      elem.classList.add("splide__pages");

      const destination =
        list?.parentElement?.parentElement?.querySelector(".splide__buttons");
      if (destination) {
        destination.appendChild(elem);
      } else {
        list?.parentElement?.appendChild(elem);
      }

      update();
      Splide.on("move", update);
    };
    const update = () => {
      elem.textContent = `${Splide.index + 1}/${Splide.length}`;
    };
    return {
      mount,
    };
  };

  const list = root.querySelector(".splide__list");
  if (list && list.childNodes.length > 1) {
    new Splide(root, {
      autoplay: true,
      pagination: false,
      pauseOnHover: false,
      pauseOnFocus: false,
      mediaQuery: "min",
      perPage: 2,
      perMove: 1,
      gap: 14,
      breakpoints: {
        768: {
          destroy: true,
        },
      },
    }).mount({
      slideNumber,
    });
  }
}

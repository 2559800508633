/* eslint-disable no-console */
import { queryForTarget } from "core/content";

import { type FocusOption, goals, type ServiceOption, services } from "./data";
import focusIcons from "./icons/focuses";
import serviceIcons from "./icons/services";

function createFocusAreaElement(option: FocusOption) {
  const element = document.createElement("div");

  element.classList.add("care-card__radio-button-field");
  element.classList.add("disablehighlights");

  element.setAttribute("dk-value", option.id);

  const iconWrapperElement = document.createElement("div");

  if (focusIcons[option.id]) {
    iconWrapperElement.innerHTML = focusIcons[option.id];
  }

  element.appendChild(iconWrapperElement);

  const labelElement = document.createElement("span");
  labelElement.classList.add("care-card__radio-button-label");
  labelElement.innerText = option.label;
  element.appendChild(labelElement);

  return element;
}

function createServiceCardElement(service: ServiceOption) {
  const element = document.createElement("div");
  element.className = "care-card__service-card";

  const innerElement = document.createElement("div");
  innerElement.className = "care-card__service-card-inner";

  const headerElement = document.createElement("div");
  headerElement.className = "care-card__service-card-header";
  innerElement.appendChild(headerElement);

  const rowElement = document.createElement("div");
  rowElement.className = "row";
  rowElement.style.alignItems = "center";
  headerElement.appendChild(rowElement);

  const icon = serviceIcons[service.id] ?? serviceIcons.placeholder;

  const iconElement = document.createElement("span");
  iconElement.classList.add("care-card__service-icon");
  iconElement.style.backgroundColor = icon.color;
  iconElement.style.alignSelf = "center";
  iconElement.innerHTML = icon.svg;
  rowElement.appendChild(iconElement);

  const sessionElement = document.createElement("div");
  sessionElement.className = "header-session-length";
  rowElement.appendChild(sessionElement);

  const labelElement = document.createElement("h3");
  labelElement.innerText = service.label;
  sessionElement.appendChild(labelElement);

  const durationElement = document.createElement("p");
  durationElement.innerHTML = `<i>${service.duration} session</i>`;

  sessionElement.appendChild(durationElement);

  const descriptionElement = document.createElement("p");
  descriptionElement.style.width = "100%";
  descriptionElement.innerText = service.description;

  innerElement.appendChild(descriptionElement);

  element.appendChild(innerElement);

  return element;
}

export default function CareCard(root: HTMLElement) {
  function getCheckedElement() {
    return root.querySelector(
      ".care-card__radio-button-field[dk-checked=true]",
    );
  }

  function updateSubmitButtonState() {
    const hasChecked = getCheckedElement();
    const buttonElement = queryForTarget<HTMLButtonElement>(
      root,
      "care-card__get_results_cta",
    );

    if (buttonElement) {
      buttonElement.classList.add("notransition");
      buttonElement.disabled = !hasChecked;
    }
  }

  const resultsElement = queryForTarget<HTMLElement>(root, "care-card-results");
  const formElement = queryForTarget<HTMLElement>(root, "care-card");
  const formSectionElement = queryForTarget<HTMLElement>(
    root,
    "care-card-form-section",
  );
  const formBottomElement = queryForTarget<HTMLElement>(
    root,
    "care-card-form-bottom",
  );
  const formOptionsElement = queryForTarget<HTMLElement>(
    root,
    "care-card__field-group-options-container",
  );
  const focusAreaElement = queryForTarget(root, "sub-needs");
  const goalSelectorElement = queryForTarget<HTMLSelectElement>(
    root,
    "goal-select-field",
  );

  if (
    !formOptionsElement ||
    !formBottomElement ||
    !focusAreaElement ||
    !formElement ||
    !formSectionElement ||
    !resultsElement ||
    !goalSelectorElement
  ) {
    console.error("Missing element(s)");
    return;
  }

  const changeGoalsElement = queryForTarget<HTMLElement>(
    root,
    "results_change_goals_inner",
  );

  if (changeGoalsElement) {
    changeGoalsElement.onclick = (event: Event) => {
      event.preventDefault();
      // TODO: better way to handle resetting page state
      window.location.reload();
    };
  }

  goals.forEach((goal) => {
    const goalOptionElement = document.createElement("option");

    goalOptionElement.value = goal.id;
    goalOptionElement.innerText = goal.label;

    goalSelectorElement.appendChild(goalOptionElement);
  });

  goalSelectorElement?.addEventListener("change", (event) => {
    const goalId = (event.target as HTMLSelectElement).value;
    const goal = goals.find((goal) => goal.id === goalId);

    if (!goal) {
      return;
    }

    if (!goal.focusOptions) {
      focusAreaElement.innerHTML = "";
      formBottomElement.style.display = "none";
      return;
    }

    const hasOnlyOneOption = goal.focusOptions.length === 1;

    const focusOptionElements = goal.focusOptions.map((option) => {
      const focusAreaElement = createFocusAreaElement(option);
      focusAreaElement.setAttribute(
        "dk-checked",
        hasOnlyOneOption ? "true" : "false",
      );

      focusAreaElement.addEventListener("click", (event) => {
        event.preventDefault();

        getCheckedElement()?.setAttribute("dk-checked", "false");

        focusAreaElement.setAttribute("dk-checked", "true");

        updateSubmitButtonState();
      });

      return focusAreaElement;
    });

    focusAreaElement.replaceChildren(...focusOptionElements);

    formOptionsElement.style.display = hasOnlyOneOption ? "none" : "block";

    formBottomElement.style.display = "flex";

    updateSubmitButtonState();
  });

  formElement.addEventListener("submit", (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.target as HTMLFormElement;

    const goalId = (form.elements[0] as HTMLSelectElement).value;
    const goal = goals.find((goal) => goal.id === goalId);
    const checkedElement = getCheckedElement();

    const focus = goal?.focusOptions?.find(
      (option) => option.id === checkedElement?.getAttribute("dk-value"),
    );

    if (!focus) {
      return;
    }

    const goalTextElement = queryForTarget<HTMLElement>(
      root,
      "results-goal-text",
    );
    const focusTextElement = queryForTarget<HTMLElement>(
      root,
      "results-focus-text",
    );

    if (!goalTextElement || !focusTextElement) {
      console.error("Missing element(s)");
      return;
    }

    goalTextElement.innerText = goal?.label ?? "";
    focusTextElement.innerText = focus?.label ?? "";

    const primaryService = services.find((s) => s.id === focus.primary);

    const secondaryServices = services.filter((s) =>
      focus.secondary.includes(s.id),
    );

    const upgradeService = services.find((s) => s.id === focus.upgrade);

    const resultsServicesElement = queryForTarget(root, "results-services");

    if (primaryService) {
      resultsServicesElement?.appendChild(
        createServiceCardElement(primaryService),
      );
    }

    secondaryServices.forEach((secondaryService) =>
      resultsServicesElement?.appendChild(
        createServiceCardElement(secondaryService),
      ),
    );

    const upgradeElement = queryForTarget<HTMLElement>(root, "result-upgrade");

    if (!upgradeElement) {
      console.error("Missing element(s)");
      return;
    }

    if (upgradeService) {
      const element = createServiceCardElement(upgradeService);
      element.classList.add("care-card__service-card-upgrade");
      upgradeElement.appendChild(element);
    } else {
      const updateResultsElement = queryForTarget<HTMLElement>(
        root,
        "results-upgrade",
      );
      const betterTogetherElement = queryForTarget<HTMLElement>(
        root,
        "results-better-together",
      );
      if (updateResultsElement) {
        updateResultsElement.style.display = "none";
      }
      if (betterTogetherElement) {
        betterTogetherElement.style.display = "none";
      }
    }

    formSectionElement.style.display = "none";

    resultsElement.style.display = "block";

    // insert results to send to Salesforce into email modal
    const emailResultsFormElement = queryForTarget<HTMLFormElement>(
      root,
      "email-results-form",
    );

    if (emailResultsFormElement) {
      // TODO: build something to send to Salesforce
      const resultsDiv = document.createElement("div");
      const jsonContent = JSON.stringify(focus);
      const message = document.createElement("h4");
      message.innerText = "Data to be formatted and sent to Salesforce:";
      resultsDiv.innerHTML = jsonContent;
      emailResultsFormElement.appendChild(message);
      emailResultsFormElement.appendChild(resultsDiv);
    } else {
      console.error("Missing element(s) emailResultsFormElement");
    }

    // TODO: also add component to handle form submission, once we know where to send it

    window.scrollTo(0, 0);
  });
}

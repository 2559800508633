import type { Components } from "@splidejs/splide";
import Splide from "@splidejs/splide";

export default function SplideHomeServices(root: HTMLElement) {
  // https://splidejs.com/guides/extension/#example
  const slideNumber = (Splide: Splide, Components: Components) => {
    const track = Components.Elements?.track;

    const mount = () => {
      const elem = document.createElement("div");
      elem.style.textAlign = "center";
      elem.style.marginTop = "0.5em";
      elem.classList.add("splide__pages");

      const destination =
        track?.parentElement?.querySelector(".splide__buttons");

      if (destination) {
        destination.appendChild(elem);
      } else {
        track?.parentElement?.appendChild(elem);
      }

      const update = () => {
        elem.textContent = `${Splide.index + 1}/${Splide.length}`;
      };

      update();

      Splide.on("move", update);
    };

    return {
      mount,
    };
  };

  const list = root.querySelector(".splide__list");

  if (list && list.childNodes.length > 1) {
    new Splide(root, {
      autoplay: true,
      pagination: false,
      pauseOnHover: false,
      pauseOnFocus: false,
      mediaQuery: "min",
      perPage: 2,
      perMove: 1,
      gap: 14,
      breakpoints: {
        1200: {
          perPage: 4,
          perMove: 1,
          gap: 48,
        },
      },
    }).mount({
      slideNumber,
    });
  }
}
